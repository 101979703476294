import React, { useRef, useEffect, useState } from "react";

const VideoComponent = ({ source }) => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting);
          if (entry.isIntersecting && !shouldLoad) {
            setShouldLoad(true);
          }
        });
      },
      {
        root: null,
        rootMargin: "100px", // Start loading slightly before it comes into view
        threshold: [0, 0.1, 0.9, 1],
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [shouldLoad]);

  useEffect(() => {
    if (videoRef.current && shouldLoad) {
      if (isVisible) {
        videoRef.current.play().catch((error) => {
          console.error("Autoplay failed:", error);
        });
      } else {
        videoRef.current.pause();
      }
    }
  }, [isVisible, shouldLoad]);

  return (
    <div ref={containerRef} style={{width:'100%'}}>
      {shouldLoad ? (
        <video
          ref={videoRef}
          loop
          muted
          playsInline
          width={'100%'}
          onCanPlay={(e) => {
            if (isVisible) {
              e.target.play().catch((error) => {
                console.error("Autoplay failed:", error);
              });
            }
          }}
        >
          <source src={source} />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div className="w-full aspect-video bg-gray-200" /> // Placeholder while video loads
      )}
    </div>
  );
};

export default VideoComponent;